import { render, staticRenderFns } from "./HomeBanner.vue?vue&type=template&id=741b7031&scoped=true"
import script from "./HomeBanner.vue?vue&type=script&lang=js"
export * from "./HomeBanner.vue?vue&type=script&lang=js"
import style0 from "./HomeBanner.vue?vue&type=style&index=0&id=741b7031&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741b7031",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TImage: require('/code/tnaot_www_pc/components/TImage.vue').default,MxLink: require('/code/tnaot_www_pc/components/MxLink.vue').default,SwiperItem: require('/code/tnaot_www_pc/components/SwiperItem.vue').default,Swiper: require('/code/tnaot_www_pc/components/Swiper.vue').default})
