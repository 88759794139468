
export default {
  name: 'FixedPage',

  props: {
    top: {
      type: Number,
      default: 88
    },
    bottom: {
      type: Number,
      default: 88
    },
    fixedStyle: {
      type: Object,
      default: () => {
        return {
          position: 'fixed',
          right: 'calc((100% - 1080px) / 2)'
        };
      }
    }
  },

  data() {
    return {
      style: {},
      height: 0
    };
  },

  computed: {
    containerStyle() {
      const { height } = this;
      if (!height) return '';
      return { height: `${height}px` };
    }
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.removeListeners();
  },

  methods: {
    init() {
      if (process.server) return;
      this.check();
      window.addEventListener('scroll', this.check);
      window.addEventListener('resize', this.check);
    },
    removeListeners() {
      if (process.server) return;
      window.removeEventListener('scroll', this.check);
      window.removeEventListener('resize', this.check);
    },
    check() {
      const { height, bottom, top, right } = this.$el.getBoundingClientRect();
      const { innerHeight, document } = window;
      const isShort = height + this.top + this.bottom < innerHeight;
      const isNeedFixed = bottom + this.bottom < innerHeight && top < this.top;
      this.height = height;

      const rightDistance = document.documentElement.clientWidth - right;

      if (isNeedFixed && isShort) {
        // 内容过短固定在顶部
        this.style = {
          ...this.fixedStyle,
          right: `${rightDistance}px`,
          top: `${this.top}px`
        };
      } else if (isNeedFixed && !isShort) {
        // 内容过长固定在底部
        this.style = {
          ...this.fixedStyle,
          right: `${rightDistance}px`,
          bottom: `${this.bottom}px`
        };
      } else {
        this.style = {};
      }
    }
  }
};
