
import ChannelList from '../components/ChannelList.vue';
import HomeBanner from '../components/HomeBanner.vue';
import TopicAndNews from '../components/TopicAndNews.vue';
import TopNews from '../components/TopNews.vue';
import Banner from '../components/Banner.vue';
import { mergeSeoHead } from '@/seo/index';

export default {
  name: 'IndexPage',

  components: {
    HomeBanner,
    TopNews,
    ChannelList,
    TopicAndNews,
    Banner
  },

  async asyncData({ $API }) {
    const [
      dailyBestRes,
      topRes,
      topicRes,
      latestNewsListRes,
      listDataRes,
      bannerListRes
    ] = await Promise.all([
      $API.articleGetSelectedNews(),
      $API.articleGetTopNews(),
      $API.subjectListHots(),
      $API.articleGetLastestNews(),
      $API.articleGetChannelListContent(),
      $API.articleGetBannerList({ sourceType: 16 })
    ]);

    return {
      dailyList: dailyBestRes,
      topList: topRes,
      hotTopicList: topicRes,
      latestNewsList: latestNewsListRes,
      listData: listDataRes,
      bannerList: bannerListRes
    };
  },

  data() {
    return {
      dailyList: [], // 每日精选
      topList: [], // 置顶新闻
      hotTopicList: [], // 热门专题
      latestNewsList: [], // 最新资讯
      listData: [], // 频道模块
      bannerList: []
    };
  },

  // head() {
  //   const isZH = this.language === LANGUAGE.ZH;
  //   return {
  //     title: isZH
  //       ? '柬埔寨头条柬埔寨中柬媒体|柬埔寨商业动态|柬埔寨房产|柬埔寨新闻|柬埔寨生活攻略 - tnaot.com'
  //       : 'TNAOT APP Chinese and Cambodian bilingual media - tnaot.com',
  //     meta: [
  //       // hid is used as unique identifier. Do not use `vmid` for it as it will not work
  //       {
  //         hid: 'description',
  //         name: 'description',
  //         content: '《柬埔寨头条》中柬双语内容平台，承接全媒体投放，柬埔寨本土最活跃APP，及时提供柬埔寨的各类资讯、生活攻略、商业服务、政策解读，是柬埔寨最全内容平台'
  //       },
  //       {
  //         hid: 'keywords',
  //         name: 'keywords',
  //         content: '柬、柬埔寨、柬埔寨头条、柬埔寨地图、金边、中文媒体、柬文媒体、高棉生活、话费充值、'
  //       }
  //     ]
  //   };
  // },

  head() {
    const head = {
      title: this.$t('tnaot_title'),
      meta: [{ property: 'og:type', content: 'website' }]
    };
    return mergeSeoHead(
      head,
      this.$getMainSeoHead({
        title: this.$t('tnaot_title'),
        image: 'https://www.tnaot.com/img/logo-256.png',
        url: 'https://www.tnaot.com/' + this.locale + '/m',
        keyword: this.$t('indexKeywords'),
        description: this.$t('indexDescription')
      })
    );
  },
};
