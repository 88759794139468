
import ScrollToFixed from '../components/ScrollToFixed.vue';
import HotTopic from '../components/HotTopic.vue';
import LatestNews from '../components/LatestNews.vue';

export default {
  comments: {
    HotTopic,
    LatestNews,
    ScrollToFixed
  },

  props: {
    topicList: {
      type: Array,
      default: () => []
    },
    newsList: {
      type: Array,
      default: () => []
    }
  }
};
