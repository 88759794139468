
import { NEWS_PAGE_PATH_MAP } from '@/constants/index.js';

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      speed: 50,
      duration: 0
    };
  },

  mounted() {
    this.duration =
      this.$refs.marquee.getBoundingClientRect().width / this.speed + 's';
  },

  methods: {
    jumpPage({ newsType, newsId }) {
      this.$router.push({
        path: this.localePath(
          `${NEWS_PAGE_PATH_MAP[newsType]}/${newsId}`,
          this.$i18n.locale
        )
      });
    }
  }
};
