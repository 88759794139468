
import Channel from './Channel.vue';
import NoMore from './NoMore.vue';

export default {
  components: { Channel, NoMore },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
