import { render, staticRenderFns } from "./index.vue?vue&type=template&id=088080e2&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=088080e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088080e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBanner: require('/code/tnaot_www_pc/components/HomeBanner.vue').default,Banner: require('/code/tnaot_www_pc/components/Banner.vue').default,TopNews: require('/code/tnaot_www_pc/components/TopNews.vue').default,ChannelList: require('/code/tnaot_www_pc/components/ChannelList.vue').default,TopicAndNews: require('/code/tnaot_www_pc/components/TopicAndNews.vue').default})
