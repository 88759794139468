import { render, staticRenderFns } from "./LatestNews.vue?vue&type=template&id=5814330b&scoped=true"
import script from "./LatestNews.vue?vue&type=script&lang=js"
export * from "./LatestNews.vue?vue&type=script&lang=js"
import style0 from "./LatestNews.vue?vue&type=style&index=0&id=5814330b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5814330b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TImage: require('/code/tnaot_www_pc/components/TImage.vue').default,Icon: require('/code/tnaot_www_pc/components/Icon.vue').default,MxLink: require('/code/tnaot_www_pc/components/MxLink.vue').default})
